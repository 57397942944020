<template>
  <div class="recordBox">
    <div class="record">
      <div class="itemBox" @click="goLink('goodsAttention')">
        <figure class="itemImg">
          <img
            class="itemImgIcon"
            src="~assets/img/userManage/icon_mine_goods@2x.png"
          />
        </figure>
        <p class="itemTitle">{{ $t.userManage.attentionGoods }}</p>
      </div>
      <div class="itemBox" @click="goLink('shopAttention')">
        <figure class="itemImg">
          <img
            class="itemImgIcon"
            src="~assets/img/userManage/icon_mine_shop@2x.png"
          />
        </figure>
        <p class="itemTitle">{{ $t.userManage.attentionShop }}</p>
      </div>
      <div class="itemBox" @click="goLink('track')">
        <figure class="itemImg">
          <img
            class="itemImgIcon"
            src="~assets/img/userManage/icon_mine_record@2x.png"
          />
        </figure>
        <p class="itemTitle">{{ $t.userManage.browsingHistory }}</p>
      </div>
      <div class="itemBox" @click="goLink('support')">
        <figure class="itemImg">
          <img
            class="itemImgIcon"
            src="~assets/img/userManage/icon_mine_help@2x.png"
          />
        </figure>
        <p class="itemTitle">{{ $t.support.helpCenter }}</p>
      </div>
      <div class="itemBox" @click="goLink('coupon')">
        <figure class="itemImg">
          <van-badge
            color="#ff5852"
            :content="userData.CouponCount ? userData.CouponCount : null"
          >
            <img
              class="itemImgIcon"
              src="~assets/img/userManage/icon_coupon@2x.png"
            />
          </van-badge>
        </figure>
        <p class="itemTitle">{{ $t.userManage.coupon }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Badge } from "vant";
Vue.use(Badge);

export default {
  name: "Record",
  props: ["userData"],
  data() {
    return {};
  },
  methods: {
    // 跳转
    goLink(name) {
      var isParams = false;
      var query = [];
      if (name == "coupon") {
        isParams = true;
        query = [
          {
            name: "type",
            value: 1,
          },
        ];
      }
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: isParams,
        path: "user/" + name,
        name: name,
        query: query,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.recordBox {
  margin-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  //background-color: #ffffff;
  background: linear-gradient(0deg, #f6f6f6 0%, #ffffff 100%);
  background: -webkit-linear-gradient(0deg, #f6f6f6 0%, #ffffff 100%);
  .record {
    width: 95%;
    margin: 0 auto;
    .itemBox {
      padding-top: 15px;
      text-align: center;
      width: 25%;
      display: inline-block;
      .itemImg {
        width: 42px;
        align-items: flex-end;
        -webkit-align-items: flex-end;
        margin: 0 auto;

        .itemImgIcon {
          width: 100%;
        }
        ::v-deep .van-badge {
          top: 10px !important;
          right: 7px !important;
          border: none !important;
          padding: 1px 1px 1px 1px;
        }
        @include publicFlex;
      }
      .itemTitle {
        color: #333333;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
    }
  }
}
</style>
